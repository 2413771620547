
import Vue from "vue";
import { TUnionRepo } from "@netvision/lib-api-repo";
import ArchivePlayer from "./ArchivePlayer.vue";
import AssignmentGroupEditor from "./AssignmentGroupEditor.vue";
import SplitButton from "primevue/splitbutton";
import WidgetAdapter from "./WidgetAdapter.vue";
import { isEqual, isMatchWith } from "lodash-es";

type WidgetAdapterProps = { name: string; props: { widgetName: string } };
type SplitButtonOption = {
  name?: "archive" | "events" | "widget" | string;
  label?: string;
  scope?: string | null;
  icon?: string;
  options?: any;
  command: (props?: any) => void;
};

const SHORT_TIME_FORMAT = new Intl.DateTimeFormat("ru", {
  // @ts-ignore
  timeStyle: "short",
  dateStyle: "short",
});

export default Vue.extend({
  name: "CameraPreview",
  props: {
    options: Object as () => CameraOptions,
    $api: Object as () => TUnionRepo,
  },
  components: {
    ArchivePlayer,
    AssignmentGroupEditor,
    SplitButton,
    WidgetAdapter,
  },
  data() {
    return {
      ...this.options,
      unmount: () => {},
      removeListener: () => {},
      displayArchivePlayer: false,
      displayAssignmentPlayer: false,
      displayAssignmentEditor: false,
      /**
       * @deprecated since version 2.2.0
       */
      currentAssignment: {} as Partial<Assignment>,
      currentAssignmentGroup: {} as IAssignmentGroup | { id: null },
      scrollPanel: {} as Element | null,
      unsubscribeCompactView: () => {},
      unsub: [] as Array<(...arg: any) => void>,
      loadingAssignmentId: null as null | string,
      widgetsShow: {} as Record<string, boolean>,
    };
  },

  methods: {
    unbreakable(str: string) {
      const maxStrLength = 20;
      let unbreakableStr = str.replace(" ", "\xa0").replace("-", "\u{2011}");
      if (unbreakableStr.length > maxStrLength) {
        unbreakableStr = unbreakableStr.slice(maxStrLength) + "...";
      }
      return unbreakableStr;
    },
    formatTime(timestamp: number) {
      try {
        return SHORT_TIME_FORMAT.format(new Date(timestamp * 1000));
      } catch (e) {
        console.error(e);
        return "";
      }
    },
    collapseExpand() {
      this.isCompactView = !this.isCompactView;
    },
    fetchAssignmentsList() {
      if ("getEntitiesList" in this.$api) {
        return this.$api
          .getEntitiesList({
            limiter: { type: "Assignment", limit: 1000 },
          })
          .then(async (response: any) => {
            if ("getPermissionsByIdsMap" in this.$api) {
              this.permissionScopes = new Map([
                ...this.permissionScopes,
                ...(await this.$api.getPermissionsByIdsMap(
                  response.results.map((e: any) => e.id)
                )),
              ]);
              this.analytics = response.results;
            }
          });
      }
    },
    fetchAssignmentGroups() {
      if ("getEntitiesList" in this.$api) {
        return this.$api
          .getEntitiesList({
            limiter: { type: "AssignmentGroup", limit: 1000 },
          })
          .then(async (response: any) => {
            if ("getPermissionsByIdsMap" in this.$api) {
              this.permissionScopes = new Map([
                ...this.permissionScopes,
                ...(await this.$api.getPermissionsByIdsMap(
                  response.results.map((e: any) => e.id)
                )),
              ]);
              this.assignmentGroups = response.results;
            }
          });
      }
    },
    toggleArchivePlayer() {
      this.displayArchivePlayer = !this.displayArchivePlayer;
      // @ts-ignore
      this.$refs?.[`${this._uid}archive`]?.toggle();
    },
    toggleAssignmentPlayer() {
      this.displayAssignmentPlayer = !this.displayAssignmentPlayer;
      // @ts-ignore
      this.$refs?.[`${this._uid}assignment`]?.toggle();
    },
    toggleAssignmentGroupEditor(
      to = false,
      assginmentGrouId = null as null | string
    ) {
      this.displayAssignmentEditor = !this.displayAssignmentEditor;
      // @ts-ignore
      this.$refs?.[`${this._uid}assignmentGroup`]?.toggle();
      if (to === false) {
        this.loadingAssignmentId = assginmentGrouId;
        this.fetchAssignmentGroups()?.then(() => {
          this.loadingAssignmentId = null;
        });
        this.fetchAssignmentsList();
      }
    },
    copyToClipboard() {
      let inputField = this.$refs[
        `coords-${this.camera.id}`
      ] as HTMLInputElement;
      inputField = Array.isArray(inputField) ? inputField[0] : inputField;
      inputField.value = `${this.camera.location}`;
      inputField.select();
      document.execCommand("copy");
      this.toast.add({
        severity: "info",
        detail: this.$t("coordinatesCopied"),
        life: 3000,
      });
    },
    scrollHorizontally(e: any) {
      e = window.event || e;
      let delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
      this.scrollPanel!.scrollLeft -= delta * 40; // Multiplied by 40
      e.preventDefault();
    },
    mountCamera() {
      const ref = this.$refs[`player-${this.camera.id}`];
      const player = this.$parent?.$parent?.areas.find(
        (e) => e.name == "player"
      );
      if (player && this.$parent?.$parent) {
        this.unmount = this.$parent.$parent.mountChildren(
          // @ts-ignore
          ref,
          player.children.map((e: any) => {
            return {
              ...e,
              props: {
                ...e.props,
                camera: { 
                  id: this.camera.id,
                  timezoneSettings: {
                    isActive: this.camera.timezoneIsActive,
                    timezone: this.camera.timezone
                  }
                },
                ratio: "fit",
                ...(this.featureLockingDisabled !== undefined
                  ? { featureLockingDisabled: this.featureLockingDisabled }
                  : {}),
              },
            };
          })
        );
      }
    },
    canI(scope: string, id: string | null = null) {
      const scopes = this.permissionScopes.get(id);
      return Array.isArray(scopes) ? scopes.includes(scope) : false;
    },
    getIcon(id: string) {
      const assignmentType =
        (this.assignmentTypes.filter(
          (e: { id: string }) => e.id === id
        )[0] as any) || ({} as any);
      return assignmentType?.iconClass || "";
    },
    getGroupIcon({ id }: IAssignmentGroup) {
      const count = this.groupAssignmentsCount[id];
      if (count === 0) return "mdi-spin mdi-loading";
      return count <= 9
        ? `mdi-numeric-${count}-box-multiple`
        : "mdi-numeric-9-plus-box-multiple";
    },
    getGroupCount(groupId: string) {
      return this.groupAssignmentsCount[groupId] || 0;
    },
    toggleWidgetAdapter(widgetName: string) {
      let ref = this.$refs[widgetName];

      ref = Array.isArray(ref) ? ref[0] : ref;
      try {
        // @ts-ignore
        ref?.toggle?.();
      } catch (error) {
        console.error(error);
      }
      this.widgetsShow = {
        ...this.widgetsShow,
        [widgetName]: !this.widgetsShow[widgetName],
      };
    },
  },
  computed: {
    canICreateCameraLocking(): boolean {
      return (
        this.canI("LockStreams", this.camera.id) &&
        ["Active", "Creation"].includes(this.camera?.blockStatus)
      );
    },
    cameraAddress(): string {
      return Array.from(
        new Set([
          this.camera.streetAddress?.trim(),
          this.camera.addressLocality?.trim(),
        ])
      )
        .filter((el) => el)
        .join(", ");
    },
    currentMode(): "default" | CameraOptions["mode"] {
      return this.mode === undefined
        ? this.isMapView
          ? "map"
          : "default"
        : this.mode;
    },
    cameraAssignmnetGroups(): IAssignmentGroup[] {
      return Array.isArray(this.assignmentGroups)
        ? this.assignmentGroups.filter(
            ({ entityId }) => entityId === this.camera.id
          )
        : [];
    },
    groupAssignmentsCount(): Record<string, number> {
      return Array.isArray(this.analytics)
        ? this.cameraAssignmnetGroups.reduce((acc, { id }) => {
            acc[id] = this.analytics.filter(
              ({ groupId }) => groupId === id
            ).length;
            return acc;
          }, {} as Record<string, number>)
        : {};
    },
    actionsMap(): Record<string, SplitButtonOption> {
      return {
        archive: {
          name: "archive",
          label: `${this.$t("button.archive")}`,
          scope: "ReadCameraArchive",
          command: this.toggleArchivePlayer,
        },
        events: {
          name: "events",
          label: `${this.$t("button.events")}`,
          scope: null,
          icon: "mdi mdi-progress-clock",
          command: ({ link }) => {
            location.href = `${link}?f__cameraId=["${this.camera.id}"]`;
          },
        },
        widget: {
          command: ({ widgetName }) => {
            this.toggleWidgetAdapter(widgetName);
          },
        },
      };
    },
    innerWidgets(): WidgetAdapterProps[] {
      return this.actions?.filter(
        ({ name, props }) => props?.widgetName && name === "widget"
      ) as WidgetAdapterProps[];
    },
    actionsOptions(): SplitButtonOption[] {
      return this.actions.reduce((acc, action) => {
        const option = { ...this.actionsMap?.[action.name], ...action };
        if (option) {
          option.command = option.command.bind(this, action.props);
          if (option.scope) {
            this.canI(option.scope, this.camera.id) && acc.push(option);
          } else {
            acc.push(option);
          }
        }
        return acc;
      }, [] as SplitButtonOption[]);
    },
    featureLockingDisabled(): boolean | undefined {
      if (!this.options.blockOnMatch) return undefined;
      return !isMatchWith(
        this.camera,
        this.options.blockOnMatch,
        (objValue: unknown, srcValue: unknown) =>
          Array.isArray(srcValue)
            ? srcValue.includes(objValue)
            : isEqual(objValue, srcValue)
      );
    },
  },
  async mounted() {
    if (typeof this.compactViewSubscriptor === "function") {
      this.unsubscribeCompactView = this.compactViewSubscriptor(
        this.camera.id,
        (val: boolean) => {
          this.isCompactView = val;
        }
      );
    }
    if ("getNotificationSocket" in this.$api)
      this.removeListener = this.$api
        .getNotificationSocket()
        .addListener("Assignment", async (entity: any) => {
          let indexFetchedAssignment = this.assignedAnalytics.findIndex(
            (e: any) => e.id === entity.id
          );
          if (indexFetchedAssignment !== -1) {
            this.assignedAnalytics.splice(indexFetchedAssignment, 1, entity);
          } else {
            if (
              entity.entityId === this.camera.id &&
              "getPermissionsByIdsMap" in this.$api
            ) {
              this.permissionScopes = new Map([
                ...this.permissionScopes,
                ...(await this.$api.getPermissionsByIdsMap([entity.id!])),
              ]);
              this.assignedAnalytics.push(entity);
            }
          }
        });
    setTimeout(() => {
      this.scrollPanel = document.querySelector(
        `#scrollPanel-${this.camera.id} > div > div.p-scrollpanel-wrapper > div.p-scrollpanel-content`
      );
      if (this.scrollPanel !== null) {
        if (this.scrollPanel.addEventListener !== undefined) {
          document;
          this.scrollPanel.addEventListener(
            "mousewheel",
            this.scrollHorizontally,
            false
          );
          document;
          this.scrollPanel.addEventListener(
            "DOMMouseScroll",
            this.scrollHorizontally,
            false
          );
        } else {
          document;
          this.scrollPanel
            // @ts-ignore
            .attachEvent("onmousewheel", this.scrollHorizontally);
        }
      }
    });
    setTimeout(this.mountCamera);
  },
  beforeDestroy() {
    this.unmount();
    this.removeListener();
    this.unsubscribeCompactView();
  },
});
